import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { StrictMode, startTransition, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";

Sentry.init({
  dsn: "https://ea4813586b74dad3c86f5617e9166ddd@o4504770065989632.ingest.us.sentry.io/4507545116213248",
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: true,
    }),
  ],
});

function clearBrowserExtensionInjectionsBeforeHydration() {
  document
    .querySelectorAll(
      [
        "html > *:not(body, head)",
        'script[src*="extension://"]',
        'link[href*="extension://"]',
      ].join(", "),
    )
    .forEach((s) => {
      s.parentNode?.removeChild(s);
    });

  const $targets = {
    html: {
      $elm: document.querySelector("html")!,
      allowedAttributes: ["lang", "dir", "class"],
    },
    head: {
      $elm: document.querySelector("head")!,
      allowedAttributes: [""],
    },
    body: {
      $elm: document.querySelector("body")!,
      allowedAttributes: ["class", "style"],
    },
  };

  Object.entries($targets).forEach(([targetName, target]) => {
    target.$elm.getAttributeNames().forEach((attr) => {
      if (!target.allowedAttributes.includes(attr)) {
        target.$elm.removeAttribute(attr);
      }
    });
  });
}

function hydrate() {
  clearBrowserExtensionInjectionsBeforeHydration();

  startTransition(() => {
    hydrateRoot(
      document,
      <StrictMode>
        <RemixBrowser />
      </StrictMode>,
    );
  });
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}
